<template>
<tbody class="table-font" style="font-size:13px;" v-if="item!=null">
    <tr>
        <td>{{index+1}}</td>
        <td>{{item.customer?.generatedid}}</td>
        <td>
            <span v-if="item.customer!=null">{{item.customer.name}}</span>
            <span v-else>{{item.customername}}</span>
        </td>
        <td>
            <span v-if="item.customer!=null"><display-mobiles :mobiles="item.customer?.mobiles"></display-mobiles></span>
            <span v-else>{{ item.customermobile}}</span>
        </td>
        <td>
            <span v-if="item.customer!=null"><display-first-address :addresses="item.customer?.addressess"></display-first-address></span>
            <span v-else>{{item.customeraddress}}</span>
        </td> 
        <td>{{item.complaintno}} <br><br> </td> 
        <td>{{ moment(item.created_at).format('DD-MM-YYYY') }}</td>
        <td style="font-size:10px ;">
            <div>
                <div>
                    <span v-if="item.product!=null && item.product.category!=null">{{item.product?.category.name}}</span>
                    <span v-if="item.product!=null && item.product.attribute!=null">
                        <div v-for="company in item.product.attribute" :key="company.id">
                        {{ getcompany(company.attribute_value_ids) }}
                        </div>
                    </span>
                    <span >{{item.product?.name}}</span>&nbsp;
                </div>
            </div>
        </td>
        <td>{{item.remark}}</td>
        <td>{{ item.updatedremark }}</td>
        
        <td>{{ item.advisor?.name}}<br>{{ item.advisor?.generatedid}}<br>
            {{ item.advisor!=null ? moment(item.created_at).format('DD-MM-YYYY H:m A') : '' }}  
        </td>
        <td><span v-if="item.enteredby!=null">{{item.enteredby.name}}<br>
            {{moment(item.created_at).format('DD-MM-YYYY H:m A')}}</span>
        </td>
        <td>{{ item.approveby?.name }} <br> {{ item.approvedate!=null? moment(item.approvedate).format('DD-MM-YYYY H:m A'):'' }}</td>
        
        <td><span v-if="item.dispatchtime != null"> {{ item.followby?.name }} <br> {{ moment(item.dispatchtime).format('DD-MM-YYYY H:m A') }}</span></td>
        <td><span v-if="item.onthewaytime!= null"> {{ item.followby?.name }} <br> {{ moment(item.onthewaytime).format('DD-MM-YYYY H:m A') }} </span></td>
        <td>
            <div v-if="item.status == 5"><span style="color: green;">{{ item.deliveredby?.name }}</span> <br> {{ moment(item.updated_at).format('DD-MM-YYYY H:m A') }}</div>
            <div v-if="item.status==2 || item.status==6"><span style="color: red;">Rejected <br>{{ moment(item.updated_at).format('DD-MM-YYYY H:m A' ) }}  </span></div>
        </td>
        <td><span :style="item.status==0 || item.status==6 || item.status==2?'color:red':'color:green'">{{ ordermethod(item)}}</span></td>
       
        <td><button class="badge btn-prime btn-xs" @click="showchats(item)">conversation</button></td>
        <!-- <td @dblclick="showfiled=!showfiled" @keyup.enter="showfiled=!showfiled">
            <span v-if="item.followby!=null">{{item.followby.name}}</span>
            <select v-if="showfiled" v-model="followedid" class="form-control form-cascade-control input-small" @change="showfollowedfield(item)">
                <option v-for="filter in employees" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
            </select> 
        </td> -->
    </tr>
    <tr v-if="visitcomments">
        <td colspan="18">
            <order-conversation :item="item" :show="true"></order-conversation>
        </td>
    </tr>
</tbody>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import OrderConversation from './OrderConversation.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import Constants from '../../components/utilities/Constants.vue'
export default {
    props:['item','index'],
    components:{OrderConversation,DisplayFirstAddress,DisplayMobiles},
    mixins:[Constants],
    data() {
        return {
            moment:moment,
            visitcomments:false,
            followedid:0,
            showfiled:false
        }
    },
    computed:{
        ...mapGetters(['employees','loggedinuser','attributes'])
    },
    mounted() {
        if(this.item.followby!=null){
            this.followedid=this.item.followby.id
        }
        // this.$store.dispatch('fetchemployees')
    },
    methods: {
        showchats(){
            this.$store.commit('assignorderconversation',this.item)
            this.visitcomments=!this.visitcomments
        },
        ordermethod(item){
            let type="";
            switch (item.status) {
                case 0:
                    type= "Pending Order";
                    break;
                case 1:
                    type= "Approved";
                    break;
                case 2:
                    type= "Rejected";
                    break;
                case 3:
                    type= "Order Dispatch";
                    break;
                case 4:
                    type= "On the Way";
                    break;
                case 5:
                    type= "Order Delivered";
                    break;
                case 6:
                    type= "Order Rejected";
                    break;
                default:
                    break;
            }
            return type;
        },
        requestmethod(item){
            let type="";
            switch (item.requesttype) {
                case 1:
                    type= "QUERY";
                    break;
                case 2:
                    type= "Complaint";
                    break;
                case 3:
                    type= "Feedback";
                    break;
                case 4:
                    type= "ORDER";
                    break;
                default:
                    break;
            }
            return type;
        },
        leadbymethod(item){
            let type="";
            switch (item.leadtype) {
                case 1:
                    type= "INCOMING CALL";
                    break;
                case 2:
                    type= "OUTGOING CALL";
                    break;
                case 3:
                    type= "NEWS PAPER ADD";
                    break;
                case 4:
                    type= "SHOW ROOM WALKIN";
                    break;
                case 5:
                    type= "HOME VISIT";
                    break;
                case 6:
                    type= "REFFERRED BY OTHER";
                    break;
                case 7:
                    type= "INTERNET";
                    break;
                case 8:
                    type= "BY ADVISOR";
                    break;
                default:
                    break;
            }
            return type;
        },
        refresh(){
            this.$parent.refresh()
        },
        // updatefield(){

        // },
        showfollowedfield(item){
            if(this.loginusercheck(170)){
                this.showfiled=!this.showfiled
                let param={id:item.id,column:"followedid",value:this.followedid}
                this.$http.post('api/leads/updatefield',param)
                    .then((response) =>this.processUpdateResponse(response.data))
                    .catch((err) => {
                        console.log('', err)
                    });
            }else{
                alert('You Dont have Right')
            }
            
        },
        processUpdateResponse(data){
            // this.showfiled=false
            this.$parent.refresh()
            this.$notify({
                text:'Field Updated Successfully',data,
                type:'success',
                duration:'-1',
                speed:'100',
                closeOnClick:false
            })
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
    },
}
</script>
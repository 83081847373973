import { render, staticRenderFns } from "./PendingOrder.vue?vue&type=template&id=96a9706c"
import script from "./PendingOrder.vue?vue&type=script&lang=js"
export * from "./PendingOrder.vue?vue&type=script&lang=js"
import style0 from "./PendingOrder.vue?vue&type=style&index=0&id=96a9706c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports